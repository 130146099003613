import React, { ReactElement, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import getCallback from '../api/getCallback';

const CallbackPage = (): ReactElement => {
  const query = useLocation().search;
  const history = useHistory();

  useEffect(() => {
    const code = (new URLSearchParams(query)).get('code');
    if (!code) {
      history.push('/');
    } else {
      getCallback(code).then((token) => {
        localStorage.setItem('le_token', token);
        history.push('/');
      }).catch(() => {
        history.push('/login');
      });
    }
  }, [query, history]);

  return (
    <div className="h-1 pt-16 pb-12 flex flex-col bg-white">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 flex justify-center">
          <svg
            className="animate-spin -ml-1 mr-3 h-48 w-48 text-gray-900"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div>
        <div className="py-16">
          <div className="text-center">
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Bitte warten!</h1>
            <p className="mt-2 text-base text-gray-500">Ihre Anmeldedaten werden überprüft.</p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CallbackPage;
