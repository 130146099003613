import React, { ReactElement, useEffect, useState } from 'react';
import { Popover } from '@headlessui/react';
import {
  MenuIcon,
} from '@heroicons/react/outline';
import { useHistory } from 'react-router-dom';
import getMe from '../api/getMe';
import Button from './elements/Button';

const NavBar = (): ReactElement => {
  const [me, setMe] = useState<boolean>();
  const history = useHistory();

  function loadUser(): void {
    getMe().then(() => {
      setMe(true);
    }).catch(() => {
      setMe(false);
    });
  }

  useEffect(() => {
    loadUser();
    history.listen(() => {
      loadUser();
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function logout(): void {
    localStorage.removeItem('le_token');
    history.push('/login');
  }

  return (
    <Popover className="relative bg-white">
      <div className="mx-auto">
        <div className="border-b-2 border-gray-100">
          <div className="flex justify-between items-center py-6 justify-center md:space-x-10 px-4 sm:px-6">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="/">
                <img
                  className="h-8 w-auto sm:h-10"
                  src={`${process.env.PUBLIC_URL}/logo.png`}
                  alt="Logo"
                />
              </a>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              >
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              {
                  me && (
                  <Button onClick={() => logout()} color="primary" size="medium">
                    Logout
                  </Button>
                  )
                }
            </div>
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default NavBar;
